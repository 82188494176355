import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// import DownloadNew from "../assets/images/icons/apk.png";
import DownloadNew from "../assets/images/icons/apk-icon.png";
import AllCasinoIconNew from "../assets/images/icons/casino-chips.png";
import HomeIconNew from "../assets/images/icons/home.png";
import SportsIconNew from "../assets/images/icons/football.png";
import ExchangeIcon from "../assets/images/icons/exchange.png";
import AviatorIcon from "../assets/images/icons/Aviator.png";

const BottomNav = () => {
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const isApk = localStorage.getItem("isApk") === "true";
  const footerPages = [
    "sports",
    "betby",
    "home",
    "allCasino",
    "gatewaylist",
    "aviator",
  ];
  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);
  return (
    <div>
      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li>
            <a
              href="/sports/Cricket"
              className={activePage === "sports" ? "active" : ""}
            >
              <img src={ExchangeIcon} alt="ExchangeIcon" />
              <span>Exchange</span>
            </a>
          </li>
          <li>
            <a
              href="/casino/spribe/aviator"
              className={activePage === "aviator" ? "active" : ""}
            >
              <img src={AviatorIcon} alt="ExchangeIcon" />
              <span>Aviator</span>
            </a>
          </li>
          <li>
            <a href="/home" className={activePage === "home" ? "active" : ""}>
              <img src={HomeIconNew} alt="ExchangeIcon" />

              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              href="/allCasino"
              className={activePage === "allCasino" ? "active" : ""}
            >
              <img src={AllCasinoIconNew} alt="ExchangeIcon" />

              <span>All Casino</span>
            </a>
          </li>
          {!isApk && (
            <li>
              <a
                href="https://dl.dropbox.com/scl/fi/4zbe8k2ri3d5nvv2ef5u5/Govindaexchange.apk?rlkey=u0i6su1qmpgacsmpgus8n3a94&st=si2rc4lj&dl=0"
                download="https://dl.dropbox.com/scl/fi/4zbe8k2ri3d5nvv2ef5u5/Govindaexchange.apk?rlkey=u0i6su1qmpgacsmpgus8n3a94&st=si2rc4lj&dl=0"
                className={
                  activePage === "gatewaylist apkdownload" ? "active" : ""
                }
              >
                <img src={DownloadNew} alt="ExchangeIcon" />

                <span className="apkdownload">Download APK</span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default BottomNav;
