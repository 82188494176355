import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import LeftBarSports from "../../../components/LeftBarSports";
import EzugiProvider from "../../../../assets/images/banner/Ez.png";
import EvolutionProvider from "../../../../assets/images/banner/EGaming.png";
import VivoProvider from "../../../../assets/images/banner/VivoGaming.png";
import QtechProvider from "../../../../assets/images/banner/QTech.png";
import worldcasino from "../../../../assets/images/banner/worldcasino.png";
import xpg from "../../../../assets/images/banner/xpg.png";
import supernowa from "../../../../assets/images/banner/supernowa.png";
import BaccaratClassic from "../../../../assets/images/banner/BaccaratClassic.jpg";
import SicBo from "../../../../assets/images/banner/SicBo.jpg";
import RedBlueDuel from "../../../../assets/images/banner/RedBlueDuel.jpg";
import awcthaifishprawncrab from "../../../../assets/images/banner/awcthaifishprawncrab.jpg";
import ExtraAndarBahar from "../../../../assets/images/banner/ExtraAndarBahar.jpg";

import Footer from "../../../../containers/Footer";
const AeSexy = () => {
  return (
    <>
      <HeaderAfterLogin />{" "}
      <main className="main mt-5" style={{ height: "100vh" }}>
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="container">
          <div className="game-heading mb-4">
            <h3>AeSexy</h3>
          </div>
          <div>
            <div className="providerSection">
              <ul style={{ justifyContent: "flex-start" }}>
                <li>
                  <a href="/casino/awc-baccarat-classic">
                    <img src={BaccaratClassic} />
                  </a>
                </li>
                <li>
                  <a href="/casino/awc-extra-sicbo">
                    <img src={SicBo} />
                  </a>
                </li>
                <li>
                  <a href="/casino/awc-red-blue-duel">
                    <img src={RedBlueDuel} />
                  </a>
                </li>
                <li>
                  <a href="/casino/awc-extra-andar-bahar">
                    <img src={ExtraAndarBahar} />
                  </a>
                </li>
                <li>
                  <a href="/casino/awc-thai-fish-prawn-crab">
                    <img src={awcthaifishprawncrab} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default AeSexy;
